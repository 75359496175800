@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
  /**
  @font family declaration
  */
  --tp-ff-body: 'Jost', sans-serif;
  --tp-ff-heading: 'Jost', sans-serif;
  --tp-ff-p: 'Jost', sans-serif;
  --tp-ff-jost: 'Jost', sans-serif;
  --tp-ff-fontawesome: "Font Awesome 6 Pro";
  --tp-ff-roboto: 'Roboto', sans-serif;
  --tp-ff-oregano: 'Oregano', cursive;
  --tp-ff-charm: 'Charm', cursive;
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-common-black-solid: #000;
  --tp-common-black: #010F1C;
  --tp-yellow-1: #FFB342;
  --tp-yellow-2: #FFD43A;
  --tp-yellow-3: #FFB21D;
  --tp-pink-1: #FD4B6B;
  --tp-pink-2: #FD2D6C;
  --tp-pink-3: #FF296A;
  --tp-pink-4: #FF3494;
  --tp-green-dark: #115061;
  --tp-green-1: #31B757;
  --tp-khaki-1: #AB9774;
  --tp-blue-1: #0989FF;
  --tp-orange-1: #FF6736;
  --tp-heading-primary: #010F1C;
  --tp-heading-secondary: #021D35;
  --tp-grey-1: #F6F7F9;
  --tp-grey-2: #ECF2F7;
  --tp-grey-3: #CAD4DB;
  --tp-grey-4: #F8F8F8;
  --tp-grey-5: #EFF0ED;
  --tp-grey-6: #EFF1F5;
  --tp-grey-7: #F6F6F6;
  --tp-grey-8: #EAE4DE;
  --tp-grey-9: #E5E9F2;
  --tp-text-body: #55585B;
  --tp-text-1: #767A7D;
  --tp-text-2: #55585B;
  --tp-text-3: #A0A2A4;
  --tp-text-4: #7D7F82;
  --tp-text-5: #888A8C;
  --tp-text-6: #90969B;
  --tp-theme-primary: #0989FF;
  --tp-theme-secondary: #821F40;
  --tp-theme-brown: #BD844C;
  --tp-theme-green: #678E61;
  --tp-footer-grey-1: #F4F7F9;
  --tp-border-primary: #EAEBED;
  --tp-border-secondary: #F0F2F4;
  --tp-border-3: #E4E5E9;
  --tp-border-4: #E6E7E8;
}
body{font-family: "Roboto", "Jost", system-ui,-apple-system,Segoe UI, sans-serif;
  line-height: 1.5; color: #1f1f1f;}
main img{max-width:100%;}
.bg-gray-100{background-color: #f5f5fa;}
.content1x{max-width: 1000px; margin: auto;}
header{background-image: linear-gradient(89.92deg, #6eb0ee 2.93%, #0164c0 89.38%);padding-top: 10px;}
header .top-header{padding: 15px 0px;color:#fff;font-size: 14px;position: relative;margin-bottom: 15px;}
header .top-header:before{content:'';background-image: linear-gradient(89.98deg, hsla(0, 0%, 100%, 0), #fff 50.1%, hsla(0, 0%, 100%, 0));width: 100%;height: 1px;position: absolute;bottom: 0px;left: 0px;}
header .top-header a{color:#fff; margin-left: 5px;}
header .top-header a:hover{color:#ff0;}
header .top-header .hotline {
  color: #000;
    font-weight: 500;
    padding: 9px 20px;
    border-radius: 5px;
    border: solid 1px rgb(244, 221, 156);
    background: #fff;
    animation-name: pulse;
    animation-delay: 0.2s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
header .top-header .hotline a{color:#000;}
header .lang{cursor: pointer; margin-right: 3px;}
.box-search{max-width: 500px; margin: auto;}
.box-search .form-control{height: 45px; font-size: 14px;}
.box-search .btn-search{background: #0658a4;}
.gr_account_cart .item{color:#fff;font-size: 13px;line-height: 15px;}
.gr_account_cart .account{color:#fff;font-size: 13px;line-height: 15px;position: relative;}
.gr_account_cart .account a{color:#fff;}
.gr_account_cart .account span{border: solid 1px #fff;border-radius: 50%;width: 40px;height: 40px;text-align: center;line-height: 36px;margin-right: 5px;}
.gr_account_cart a:hover{color:#ff0 !important;}
.gr_account_cart .cart a:hover{color: #f00 !important;}
.gr_account_cart .account .list-mem{position: absolute;padding: 15px;margin: 0px;top: 40px;left: 0px;background: #fff;width: 150px;z-index: 1011;border-radius: 5px;display: none;list-style: none;}
.gr_account_cart .account:hover .list-mem{display: block;}
.gr_account_cart .account .list-mem a{color:#666;display: block;border-bottom: solid 1px #ddd;padding: 7px 0px;}
.gr_account_cart .account .list-mem a:hover{color: #f00 !important;}
.gr_account_cart .account .list-mem li:last-child a{border: none;}
.gr_account_cart .cart{width: 40px; height: 40px; line-height: 40px; text-align: center; background:#fff; border-radius: 50%;}
.gr_account_cart .cart #cartNum{position: absolute;background-color: #ed1c29;width: 20px;color: #fff;height: 20px;line-height: 20px;font-size: 12px;border-radius: 50%;border: solid 1px #fff;}
#menu{position:relative;text-align:center;}
#menu ul{margin:0px;padding: 0px;}
#menu ul li{margin:0px;list-style:none;display:inline-block;position:relative;}
#menu ul li a{color:#333;font-size: 16px;padding: 12px 25px;display:block;font-weight:400;transition:all 0.5s ease;}
#menu ul li a:hover,#menu ul li a.active{color: #fff;background: #0658a4;}
#menu ul li ul{position:absolute;padding:0px;width:250px;background: #f3f3f3;display:none;z-index:1001;text-align:left;}
#menu ul li:hover > ul{display:block;}
#menu ul li ul li{display:block;border-bottom:solid 1px #e2e2e2;}
#menu ul li ul li:last-child{border:none;}
#menu ul li ul li a{padding:7px 10px;color: #404040;font-weight: normal;text-transform:none;font-size: 14px;}
#menu ul li ul li ul{left:100%;top:0px;}
#menu .dropdown-toggle-btn{
	position: absolute;
	right: 3px;
	top: 5px;
	font-size: 16px;
	color: #7F8387;
	z-index: 1;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	border: 1px solid rgba(1, 15, 28, 0.12);
	-webkit-transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	-moz-transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	-ms-transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	-o-transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
	padding: 0px;
	box-shadow: none;
	display: none;
}
#menu .dropdown-toggle-btn i{font-weight: 300;}
#menu .dropdown-toggle-btn:hover, #menu .dropdown-toggle-btn.active{color: var(--tp-common-white);background-color: var(--tp-theme-primary);border-color: var(--tp-theme-primary);}
#menu .dropdown-toggle-btn.active i{
	-webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
	transform: rotate(90deg);
	color:#fff;
}
.title_page{font-weight: bold;font-size: 25px;margin-bottom: 20px;padding-bottom: 5px;position: relative;border-bottom: solid 3px#F0F4F6;text-transform: uppercase;}
.title_page:after{background:#0164c0;content: '';width: 50px;height: 2px;position: absolute;left: 0px;bottom: -3px;}
.item_product{position: relative;border: solid 1px #eaebed;border-radius: 10px;overflow: hidden;}
.item_product .sale{position: absolute;background: #ff6736;color: #fff;padding: 0px 10px;right: 10px;top: 10px;border-radius: 5px;z-index: 1;}
.item_product .info{border-top:solid 1px #ddd;}
.item_product .info .tp-product-category a{font-size: 12px;font-weight: 500;color: #5a5a5a; line-height: 1.2 !important; display: inline-block; margin-bottom: 10px;}
.item_product .info .tp-product-rating-icon i{color:#ffb21d; font-size:12px;}
.item_product .info h3{font-weight: 700;color: #010f1c;font-size: 15px;     -webkit-box-orient: vertical; -webkit-line-clamp: 2; display: -webkit-box; overflow: hidden;}
.item_product .info h3:hover{color:#f00;}
.item_product .info .price{color:#0989ff;font-weight: 700;}
.item_product .info .price span{text-decoration: line-through;color: #55585B;font-size: 12px;font-weight: 500;}
.uudai .itemUudai{padding: 20px;color: #252525;font-size: 13px;text-align: center;}
.uudai .itemUudai .name{font-weight: bold;color: #000;font-size: 13px;margin-top: 10px;}
.tp-blog-item{position: relative;}
.tp-blog-item .tp-blog-meta-date{margin-bottom: 10px;color: #858585;font-size: 13px;font-style: italic;}
.tp-blog-item .tp-blog-title{line-height: 1;}
.tp-blog-item .tp-blog-title a{color:#000;font-size: 16px;line-height: 20px;}
.tp-blog-item-outstand.tp-blog-item .tp-blog-title a{font-size: 1.75rem;}
.tp-blog-item .tp-blog-content p{font-size: 14px;line-height: 1.43;color: #55585b;-webkit-box-orient: vertical; -webkit-line-clamp: 2;display: -webkit-box;overflow: hidden;}
.tp-btn-border-2, .readMore {
	border: 2px solid rgba(4, 9, 45, 0.1);
	font-weight: 500;
	font-size: 13px;
	color: var(--tp-common-black);
	background-color: transparent;
	padding: 8px 17px;
	border-radius: 5px;
	display: inline-block;
}
.postDetail .date{padding: 10px 0px;border-top:solid 1px #ddd;border-bottom:solid 1px #ddd;text-align: center;}
.postDetail .title{font-weight: bold;font-size: 25px;position: relative;text-transform: uppercase;text-align: center;}
.ortherNews{border-top: solid 1px #ddd;padding-top: 20px;}
.ortherNews .title_other{color:#5065a1;font-weight: 700;font-size: 28px;margin-bottom: 30px;}
footer{background-image: linear-gradient(89.92deg, #6eb0ee 2.93%, #0164c0 89.38%);padding: 50px 0px;color:#fff;font-size: 14px;}
footer .tp-footer-widget-title{font-weight: 500;font-size: 17px;text-transform: uppercase;}
footer a{color:#fff;}
footer a:hover{color:#ff0;}
footer ul{padding: 0px;list-style: none;}
footer ul li a{
    color: #fff;
    margin-bottom: 5px;
    display: block;
}
.tp-footer-social a {display: inline-block;width: 38px;height: 38px;line-height: 38px;text-align: center;background: #FFFFFF;box-shadow: 0px 1px 1px rgba(1, 15, 28, 0.2);border-radius: 6px;margin-right: 5px;}
.tp-footer-social a i{color:#000;}
.contentDetail{position: relative;}
.contentDetail .viewMore{position:absolute;width:100%;/* height:60px; */bottom: 0px;text-align:center;padding: 20px 0px;background: linear-gradient(to bottom, transparent 0%, #fff 40%) !important;}
.contentDetail .viewMore .btn_readmore{border:solid 1px #239808;color:#239808;transition:all 0.5s ease;display: inline-block;}
.contentDetail .viewMore .btn_readmore:hover{stroke:#fff;background:#239808;color:#fff}
.contentDetail .viewMore .btn_readmore:hover svg path{stroke:#fff !important}
.mh-200{height: 200px; overflow: hidden;}
.grid_container{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
}
.grid_container1{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 20px;
}
.columns-md-1{grid-column-end: span 1;}
.columns-md-2{grid-column-end: span 2;}
.columns-md-3{grid-column-end: span 3;}
.columns-md-4{grid-column-end: span 4;}
.columns-md-5{grid-column-end: span 5;}
.columns-md-6{grid-column-end: span 6;}
.columns-md-7{grid-column-end: span 7;}
.columns-md-8{grid-column-end: span 8;}
.columns-md-9{grid-column-end: span 9;}
.columns-md-10{grid-column-end: span 10;}
.columns-md-11{grid-column-end: span 11;}
.columns-md-12{grid-column-end: span 12;}


#loading {
	background-color: #fff;
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 999999;
	margin-top: 0px;
	top: 0px;
  }
  
  #loading-center {
	width: 100%;
	height: 100%;
	position: relative;
  }
  
  #loading-center-absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
  }
.tp-preloader-content {
  text-align: center;
}
.tp-preloader-logo {
  width: 180px;
  height: 180px;
  line-height: 120px;
  position: relative;
  text-align: center;
  margin: auto;
  padding: 30px;
}
.tp-preloader-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tp-preloader-circle svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: tp-rotate 5s linear infinite;
  -moz-animation: tp-rotate 5s linear infinite;
  -ms-animation: tp-rotate 5s linear infinite;
  -o-animation: tp-rotate 5s linear infinite;
  animation: tp-rotate 5s linear infinite;
}
.tp-preloader-circle svg circle:last-child {
  stroke: #0989ff;
  stroke-dashoffset: 0;
  stroke-dasharray: 1128, 3150;
  -webkit-animation: tp-loading 4s linear infinite;
  -moz-animation: tp-loading 4s linear infinite;
  -ms-animation: tp-loading 4s linear infinite;
  -o-animation: tp-loading 4s linear infinite;
  animation: tp-loading 4s linear infinite;
  transform-origin: center center;
}

/*----------------------------------------*/
/*  Login CSS
/*----------------------------------------*/
.breadcrumb__title {
  font-weight: 900;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.breadcrumb__list span {
  font-weight: 400;
  font-size: 14px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #666;
}
.breadcrumb__list span a {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  max-width: 270px;
}
.breadcrumb__list span:not(:last-child) {
  padding-right: 12px;
  margin-right: 5px;
}
.breadcrumb__list span:not(:last-child)::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  background-color: #A8ACB0;
  border-radius: 50%;
}
.tp-login-wrapper {
  box-shadow: 0px 30px 70px rgba(1, 15, 28, 0.1);
  padding: 50px 60px 70px;
  background-color: var(#fff);
}
@media (max-width: 575px) {
  .tp-login-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.tp-login-title {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 4px;
}
.tp-login-top p {
    font-weight: 400;
    font-size: 16px;
    color: #49535B;
}
.tp-login-mail {
  position: relative;
  z-index: 1;
}
.tp-login-mail p {
  font-size: 15px;
  color: #55585B;
  margin-bottom: 0;
  padding: 0 20px;
  position: relative;
  display: inline-block;
  background-color: #fff;
}
.tp-login-mail::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 12px;
  width: 100%;
  height: 1px;
  background-color: #E0E2E3;
  z-index: -1;
}
.tp-login-input-wrapper {
  margin-bottom: 14px;
}
.tp-login-input-box {
  position: relative;
}
.tp-login-input-box:not(:last-child) {
  margin-bottom: 34px;
}
.tp-login-input input {
  height: 56px;
  background: #FFFFFF;
  border: 1px solid #E0E2E3;
  font-size: 14px;
  color: #010F1C;
}
.tp-login-input input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #95999D;
}
.tp-login-input input::-moz-placeholder { /* Firefox 19+ */
  color: #95999D;
}
.tp-login-input input:-moz-placeholder { /* Firefox 4-18 */
  color: #95999D;
}
.tp-login-input input:-ms-input-placeholder { /* IE 10+  Edge*/
  color: #95999D;
}
.tp-login-input input::placeholder { /* MODERN BROWSER */
  color: #95999D;
}
.tp-login-input-title label {
  font-size: 14px;
  color: #010F1C;
  position: absolute;
  top: -7px;
  left: 20px;
  padding: 0 5px;
  background-color:#fff;
  line-height: 1;
}
.tp-login-input-eye {
  position: absolute;
  right: 26px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.tp-login-input-eye .open-eye {
  display: none;
}
.tp-login-input-eye:hover {
  cursor: pointer;
}
.tp-login-input-eye:hover span {
  color: #010F1C;
}
.tp-login-remeber input {
  display: none;
}
.tp-login-remeber input:checked ~ label::after {
  background-color: #0989ff;
  border-color:#0989FF;
}
.tp-login-remeber input:checked ~ label::before {
  visibility: visible;
  opacity: 1;
}
.tp-login-remeber label {
  font-size: 15px;
  color: #55585B;
  position: relative;
  padding-left: 26px;
  z-index: 1;
}
.tp-login-remeber label::after {
  position: absolute;
  content: "";
  top: 4px;
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #C3C7C9;
  z-index: -1;
  -webkit-transition: all 0.2s 0s ease-out;
  -moz-transition: all 0.2s 0s ease-out;
  -ms-transition: all 0.2s 0s ease-out;
  -o-transition: all 0.2s 0s ease-out;
  transition: all 0.2s 0s ease-out;
}
.tp-login-remeber label::before {
  position: absolute;
  content: url("../img/product/icons/check.svg");
  top: 4px;
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  color: #fff;
  -webkit-transition: all 0.2s 0s ease-out;
  -moz-transition: all 0.2s 0s ease-out;
  -ms-transition: all 0.2s 0s ease-out;
  -o-transition: all 0.2s 0s ease-out;
  transition: all 0.2s 0s ease-out;
}
.tp-login-btn {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #010f1c;
  padding: 14px 30px;
  text-align: center;
  display: inline-block;
}
.tp-login-btn:hover {
  background-color: #0989FF;
    color:#fff;
}
.tp-login-shape-1, .tp-login-shape-2, .tp-login-shape-3, .tp-login-shape-4 {
  position: absolute;
  z-index: -1;
}
.tp-login-shape-1 {
  top: 7%;
  left: 18%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-login-shape-1 {
    left: 8%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-login-shape-1 {
    left: 4%;
  }
}
.tp-login-shape-2 {
  bottom: 38%;
  left: 26%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-login-shape-2 {
    left: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-login-shape-2 {
    left: 8%;
  }
}
.tp-login-shape-3 {
  top: 7%;
  right: 22%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-login-shape-3 {
    right: 15%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-login-shape-3 {
    right: 10%;
  }
}
.tp-login-shape-4 {
  bottom: 27%;
  right: 20%;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1600px) and (max-width: 1700px) {
  .tp-login-shape-4 {
    right: 10%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-login-shape-4 {
    right: 3%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-login-shape-4 {
    right: -6%;
  }
}
/** Css Product **/
.tp-shop-widget-title {
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 5px;
  margin-bottom: 25px;
}
.tp-shop-widget-title.no-border {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 14px;
}
.tp-shop-widget-filter-info .tp-shop-widget-filter-btn {
    font-weight: 400;
    font-size: 14px;
    padding: 2px 21px;
}
.tp-shop-widget-categories{
	height: 288px;
  overflow-y: scroll;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scrollbar-width: thin;
  padding-right: 10px;
}
.tp-shop-widget-content ul{padding: 0px;}
.tp-shop-widget-content ul li {
  list-style: none;
  width: 100%;
}
.tp-shop-widget-content ul li:not(:last-child) {
  margin-bottom: 10px;
}
.tp-shop-widget-content ul li a {
  font-weight: 400;
  font-size: 15px;
  color: var(--tp-text-body);
  position: relative;
  padding-left: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tp-shop-widget-content ul li a::after {
  position: absolute;
  content: "";
  top: 10px;
  left: 0;
  width: 6px;
  height: 6px;
  text-align: center;
  background-color: #E7E7E7;
  border-radius: 50%;
}
.tp-shop-widget-content ul li a:hover {
  color: var(--tp-theme-primary);
}
.tp-shop-widget-content ul li a:hover::after {
  background-color: var(--tp-theme-primary);
}
.tp-shop-widget-content ul li a:hover span {
  background-color: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.tp-shop-widget-content ul li a span {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  border: 1px solid #EAEAEA;
  border-radius: 8px;
  padding: 5px 6px 3px;
}
.tp-shop-widget-product-thumb img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  margin-right: 14px;
}
.tp-shop-widget-product-rating span {
    font-size: 12px;
    color: #FFB21D;
    margin-right: 1px;
}
.tp-shop-widget-product-rating-number span {
    font-weight: 500;
    font-size: 12px;
    color: #818487;
}
.tp-shop-widget-product-title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    color: var(--tp-heading-primary);
    margin-bottom: 10px;
}
.tp-shop-widget-product-title a {
	color: var(--tp-heading-primary);
}
.tp-shop-widget-brand-item {
  width: 50%;
  flex: 0 0 50%;
  margin-bottom: 30px;
}
.tp-shop-top-select .nice-select {
    background-color: #F9F9F9;
    border: 1px solid rgba(1, 15, 28, 0.1);
    border-radius: 0;
    font-size: 14px;
    color: var(--tp-common-black);
    height: 40px;
    line-height: 38px;
    padding: 0 25px;
    min-width: 204px;
    float: none;
}

.tp-product-details-category span {
  font-size: 16px;
  line-height: 1;
}
.name-product {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #010f1c;
  text-transform: uppercase;
  border-bottom: solid 1px #898787;
}
.product-detail .info-product{
  background: #f1f9ff;
  border-radius: 4px;
  padding: 10px 7px 0;
  border: 1px dashed #0488fa;
  font-size: 15px;
}
.tp-product-details-rating span {
  font-size: 12px;
  color: #FFB21D;
}
.tp-product-details-rating span:not(:last-child) {
  margin-right: 3px;
}
.product-detail .product_meta span{color:#07673b}
.tp-product-details-price {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: var(--tp-common-black);
}
.tp-product-details-price.new-price {
  color: #ff7800;
}
.tp-product-details-price.old-price {
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: line-through;
  color: #767A7D;
  margin-right: 20px;
}
.tp-product-details-variation {
  margin-bottom: 30px;
}
.tp-product-details-variation-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}
.tp-product-details-variation-item:not(:last-child) {
  margin-bottom: 15px;
}
.tp-product-details-variation-list button {
  display: inline-block;
  width: 26px;
  height: 26px;
  border: 1px solid rgba(1, 15, 28, 0.2);
  border-radius: 0;
  /* border-radius: 50%; */
  position: relative;
  -webkit-transition: box-shadow 0.2s 0s linear;
  -moz-transition: box-shadow 0.2s 0s linear;
  -ms-transition: box-shadow 0.2s 0s linear;
  -o-transition: box-shadow 0.2s 0s linear;
  transition: box-shadow 0.2s 0s linear;
}
.tp-product-details-variation-list button span[data-bg-color] {
  position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: var(--tp-common-white);
    /* border-radius: 50%; */
    -webkit-transition: all 0.2s 0s linear;
    -moz-transition: all 0.2s 0s linear;
    -ms-transition: all 0.2s 0s linear;
    -o-transition: all 0.2s 0s linear;
    transition: all 0.2s 0s linear;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.tp-product-details-variation-list button .tp-color-variation-tootltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(2px);
  -moz-transform: translateX(-50%) translateY(2px);
  -ms-transform: translateX(-50%) translateY(2px);
  -o-transform: translateX(-50%) translateY(2px);
  transform: translateX(-50%) translateY(2px);
  width: max-content;
  background-color: var(--tp-common-black);
  color: var(--tp-common-white);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 6px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -ms-transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -o-transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}
.tp-product-details-variation-list button .tp-color-variation-tootltip::before {
  position: absolute;
  content: "";
  bottom: -6px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid var(--tp-common-black);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.tp-product-details-variation-list button.tp-size-variation-btn {
  min-width: 40px;
  width: auto;
  height: 35px;
  border: 1px solid rgba(1, 15, 28, 0.2);
  border-radius: 0;
  -webkit-transition: all 0.2s 0s linear;
  -moz-transition: all 0.2s 0s linear;
  -ms-transition: all 0.2s 0s linear;
  -o-transition: all 0.2s 0s linear;
  transition: all 0.2s 0s linear;
  margin-right: 5px;
}
.tp-product-details-variation-list button.tp-size-variation-btn:hover, .tp-product-details-variation-list button.tp-size-variation-btn.active {
  box-shadow: none;
  border-color: #f00;
  box-shadow: none;
  color: var(--tp-common-black);
}
.tp-product-details-variation-list button:hover, .tp-product-details-variation-list button.active {
  box-shadow: 0px 1px 2px rgba(1, 15, 28, 0.2);
}
.tp-product-details-variation-list button:hover span[data-bg-color], .tp-product-details-variation-list button.active span[data-bg-color] {
  -webkit-transform: translate(-50%, -50%) scale(0.7);
  -moz-transform: translate(-50%, -50%) scale(0.7);
  -ms-transform: translate(-50%, -50%) scale(0.7);
  -o-transform: translate(-50%, -50%) scale(0.7);
  transform: translate(-50%, -50%) scale(0.7);
}
.tp-size-variation-btn.disabled, .tp-color-variation-btn.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.tp-product-details-variation-list button:hover .tp-color-variation-tootltip {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(-6px);
  -moz-transform: translateX(-50%) translateY(-6px);
  -ms-transform: translateX(-50%) translateY(-6px);
  -o-transform: translateX(-50%) translateY(-6px);
  transform: translateX(-50%) translateY(-6px);
}
.tp-product-details-action-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 13px;
}
.tp-product-details-quantity .tp-product-quantity {
  width: 122px;
  border-radius: 0;
  position: relative;
  margin-right: 10px;
}
.tp-product-details-add-to-cart{width: 200px;}
.tp-product-details-quantity .tp-cart-plus, .tp-product-details-quantity .tp-cart-minus {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  left: 14px;
  display: inline-block;
}
.tp-product-details-quantity .tp-cart-plus:hover, .tp-product-details-quantity .tp-cart-minus:hover {
  background-color: var(--tp-common-white);
  color: var(--tp-theme-primary);
}
.tp-product-details-quantity .tp-cart-plus {
  left: auto;
  right: 14px;
}
.tp-product-details-quantity .tp-cart-input[type=text] {
  height: 46px;
  line-height: 46px;
  background-color: #F3F5F6;
  border: 0;
  border-radius: 0;
  font-size: 16px;
  color: var(--tp-common-black);
  padding: 0 30px;
  text-align: center;
  width: 100%;
}
.tp-product-details-add-to-cart-btn {
  font-size: 16px;
  color: var(--tp-common-black);
  text-align: center;
  padding: 9px 30px;
  border: 1px solid #E0E2E3;
  border-radius: 5px;
}
.tp-product-details-add-to-cart-btn:hover {
  background-color: var(--tp-common-black);
  border-color: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-product-details-buy-now-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  background-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.tp-product-details-buy-now-btn:hover {
  background-color: var(--tp-common-black);
  color: var(--tp-common-white);
}
.tp-cart-plus,
.tp-cart-minus {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: var(--tp-common-black);
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.single_sidebar .item{
  border: 1px dashed #a0bfd3;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 14px;
    padding: 10px;
}
.single_sidebar .item .name{font-weight: 600;}
.single_sidebar .item p{margin: 0px;    color: #4a4a4a;}
.tp-product-details-query {
    margin-bottom: 22px;
}
.tp-product-details-query-item{color:#000;}
.tp-product-details-query-item p {
  font-size: 15px;
  margin-bottom: 0;
  line-height: 1.4;
  margin-left: 10px;
  color: #8a8a8a;
}
.tp-product-details-social {
  margin-bottom: 22px;
}
.tp-product-details-social span {
  font-size: 15px;
  color: var(--tp-common-black);
  margin-right: 2px;
}
.tp-product-details-social a {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #E6E7E8;
  border-radius: 50%;
  margin-right: 5px;
  color: #55585b;
}
.tp-product-details-social a:hover {
  background-color: var(--tp-theme-primary);
  border-color: var(--tp-theme-primary);
  color: var(--tp-common-white);
}
.selectors{max-width: 400px;margin: auto;padding: 0px 40px;}
.selectors .item_zoom{width: 100%;height: 80px;position: relative;}
.selectors .item_zoom img{display:block;-o-object-fit: cover;object-fit: cover;position: absolute;width: 100%;height: 100%;left: 0px;top: 0px;}
.thumbnail .item{width: 80px; margin: 2px;}
.mytabs{border-bottom: solid 1px #ddd; background: #0164c0; padding: 0px 10px;}
.mytabs.nav-tabs .nav-item{padding: 0px 12px;}
.mytabs.nav-tabs .nav-link{
  font-size: 17px;
    color: #fff;
    text-transform: none;
    font-weight: normal;
    padding: .8em 0 1em;
    position: relative;
    border: none;
    text-transform: uppercase;
}
.mytabs.nav-tabs .nav-link::after{
  content: "";
    width: 40px;
    height: 2px;
    position: absolute;
    bottom: 8px;
    left: calc(50% - 20px);
}
.mytabs.nav-tabs .nav-link.active, .mytabs.nav-tabs .nav-link:hover{
  color: #fff;
  border-color: #fff;
  background: none;
}
.mytabs.nav-tabs .nav-link.active::after, .mytabs.nav-tabs .nav-link:hover::after{background: #fff;}
.mb-6{    margin-bottom: 6rem !important;}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.videoWrapper iframe, .videoWrapper object, .videoWrapper .youtube-player-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.item_accessorie{border: solid 1px #ddd; padding: 5px;}
.item_accessorie h3{font-size: 14px; color:#000; text-align:center;}
.tp-product-details-desc-wrapper h2{font-size: 24px; font-weight: 700 !important;}
.tp-product-details-desc-wrapper .documents li{font-weight: bold; color: #004ebe; margin-bottom: 10px;}
.tp-product-details-desc-wrapper .documents li a{color: #004ebe;}
.tp-product-details-desc-wrapper .documents li:hover, .tp-product-details-desc-wrapper .documents li a:hover{color:#f00;}
.form-control {
  min-height: 50px;
  background: #FFFFFF;
  border: 1px solid #E0E2E3;
  font-size: 14px;
  color: var(--tp-common-black);
}
.profile__tab{box-shadow: 0px 30px 50px rgba(5, 47, 40, 0.12);}
.profile__tab .nav-link {
  font-weight: 500;
  font-size: 15px;
  color: var(--tp-text-1);
  padding: 14px 30px;
  background-color: var(--tp-common-white);
  position: relative;
  border-radius: 0;
  text-align: left;
  border: 0;
}
.profile__tab .nav-link span {
  margin-right: 7px;
}
.profile__tab .nav-link.active, .profile__tab .nav-link:hover {
  color: var(--tp-theme-primary);
  background-color: rgba(9, 137, 255, 0.06);
}
.tp-profile-input-box {
  position: relative;
  margin-bottom: 34px;
}
.tp-profile-input-title label {
  font-size: 14px;
  color: var(--tp-common-black);
  position: absolute;
  top: -7px;
  left: 20px;
  padding: 0 5px;
  background-color: var(--tp-common-white);
  line-height: 1;
}
.item_brand{
  position: relative;
  background: #fff;
  height: 80px;
  overflow: hidden;
  padding: 5px;
}
.item_brand img{
  position: absolute;
  max-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.filter-total {
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  margin-right: 8px;
  position: relative;
  padding-bottom: unset;
  float: left;
}
.filter-item__title {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 12px;
  background: #fff;
  display: inline-block;
  position: relative;
  font-size: 14px;
  height: 40px;
  margin-bottom: 10px;
}
.filter-show.show-total {
  overflow-y: auto;
  padding: 0;
  width: 900px;
  max-width: 900px;
  max-height: 90vh;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .5);
  border-radius: 4px;
  min-width: 382px;
  left: 0;
  position: absolute;
  top: 45px;
  white-space: normal;
  z-index: 3;
  padding: 20px;
  display: none;
}
.filter-total .arrow-filter::before {
  content: "";
  position: absolute;
  width: 27px;
  height: 27px;
  background: #fff;
  transform: rotate(45deg);
  top: 38px;
  left: 7px;
  box-shadow: -2px -2px 5px -4px #000;
  z-index: 10;
  display: none;
}
.filter-show.show-total.active, .filter-total .arrow-filter.active::before{display: block;}
.list-filter-active {
  padding: 0px 10px;
  display: none;
}
.list-filter-active.active{display: block; margin-top: 10px;}
.list-filter-active span{
padding: 18px 13px;
margin-right: 7px;
margin-bottom: 7px;
color: #333;
border: 1px solid #f2f4f7;
display: inline-flex;
align-items: center;
justify-content: center;
max-height: 30px;
background: rgba(242, 244, 247, 1);
border-radius: 8px;
}
.filter-item__title strong.number{
  background-color: #f89406;
  border-radius: 50%;
  color: #fff;
  display: none;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  position: absolute;
  right: 5px;
  text-align: center;
  top: -8px;
  width: 16px;
}
.filter-item__title strong.number.active{display: inline;}
.list-filter-active span i{cursor: pointer;}
.close-popup-total {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 10px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.show-total-item {
  padding: 10px 2px;
  margin-top: 10px;
}
.show-total-txt {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
}
.filter-list--hang .c-btnbox {
  height: 36px;
  margin: 0 4px 10px;
  padding: 0;
  width: 99px;
  text-align: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  overflow: hidden;
  padding: 3px;
  cursor: pointer;
}
.filter-list--hang .c-btnbox.active{border: 1px solid #4a90e2 !important;}
.c-btnbox.active, .c-btnbox:hover, .c-btnbox.check:hover {
  border: 1px solid #4a90e2;
}
.filter-border {
  border-top: 1px solid #e0e0e0;
  width: 100%;
}
.c-btnbox {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #333;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 11px 9px;
  cursor: pointer;
}
.filter-show.show-total .filter-button {
  bottom: 0;
  margin: 0;
  position: sticky;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  font-size: 0;
  padding: 8px 10px;
  text-align: center;
  border-top: 1px solid #ddd;
}
.btn-filter-close, .btn-filter-readmore {
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  margin: 0 5px;
  padding: 10px 0;
  text-align: center;
  width: 150px;
  position: relative;
}
.btn-filter-close {
  border: 1px solid #dd1c1a;
  color: #dd1c1a;
}
.btn-filter-readmore {
  background-color: #288ad6;
  color: #fff;
}
.filter-show.show-total::-webkit-scrollbar-track{border-radius:10px;background-color:#f1f1f1}
.filter-show.show-total::-webkit-scrollbar{width:8px;background-color:#f1f1f1}
.filter-show.show-total::-webkit-scrollbar-thumb{border-radius:10px;background-color:#bdbdbd}
.filter-show.show-total:hover::-webkit-scrollbar-thumb{background:#666}
.filter-item .filter-option{background-color:#fff;border-radius:4px;box-shadow:0 2px 20px rgba(0,0,0,.5);display:flex;flex-wrap:wrap;max-width:600px;min-width:382px;padding:10px;position:absolute;top:40px;white-space:normal;width:-moz-max-content;width:max-content;z-index:3; display: none; left: 50%; transform: translateX(-50%);}
.filter-item .filter-option:before {
  content: "";
  position: absolute;
  width: 27px;
  height: 27px;
  background: #fff;
  transform: rotate(45deg);
  top: -10px;
  left: 50%;
  box-shadow: -2px -2px 5px -4px #000;
  z-index: 10;
}
.filter-item .filter-option.active{display: block;}
.filter-item .filter-item__title span {
  padding-right: 12px;
  position: relative;
}
.filter-item .filter-item__title span:before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333;
  content: "";
  height: 0;
  position: absolute;
  right: 0;
  top: 6px;
  transition: .3s;
  width: 0;
}
.filter-item .filter-option_item {
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 1.5;
    margin-bottom: 6px;
    margin-right: 6px;
    padding: 8px 9px;
}
.orderby {
position: relative;
display: inline-block;
}

.head_order {
padding: 10px;
cursor: pointer;
display: inline-block;
}

ul.ordering-list {
top: 100%;
left: 0px;
right: 10px;
z-index: 999;
background: #fff;
padding: 0px;
text-align: left;
margin: 0px;
display: inline-block;
}
ul.ordering-list.active{display: block;}
ul.ordering-list li{
display: inline-block;
margin: 0 7px 15px !important;
padding: 0;
}
ul.ordering-list li button{
border: 0;
background: 0 0;
outline: none;
position: relative;
padding: 0 0 0 20px;
text-align: left;
}
ul.ordering-list li button:before{
height: 16px;
width: 16px;
border: 2px solid #ddd;
border-radius: 50%;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
display: inline-block;
vertical-align: middle;
margin: 0;
position: absolute;
left: 0;
top: 2px;
content: "";
}
ul.ordering-list li button:after{
content: "";
width: 8px;
height: 8px;
position: absolute;
border-radius: 50%;
-moz-border-radius: 50%;
-webkit-border-radius: 50%;
margin: 2px;
top: 4px;
left: 2px;
}
ul.ordering-list li button:hover:after, ul.ordering-list li button.active:after{background-color: #0076ff;}
.layer-search{position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background: rgba(0, 0, 0, .5); z-index: 2;}
.skeleton-box {
  min-height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 5s infinite;
  animation: shimmer 5s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@media (max-width: 1200px) {
  #menu ul li a{padding: 12px 18px;}
}
@media (max-width: 768px) {
ul.ordering-list{display: none; position: absolute;width: 175px;
  border: 1px solid #f3f5f7;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 10px 10px 0;
  box-shadow: rgba(99, 99, 99, .1) 0px 2px 8px 0px;
}
ul.ordering-list li{display: block;}
.filter-show.show-total {width: 100%;}
}
@media (max-width: 768px){
	body{font-size: 14px;}
	header{padding: 15px 0px;}
	/* css grid */
	.columns-sm-1{grid-column-end: span 1;}
	.columns-sm-2{grid-column-end: span 2;}
	.columns-sm-3{grid-column-end: span 3;}
	.columns-sm-4{grid-column-end: span 4;}
	.columns-sm-5{grid-column-end: span 5;}
	.columns-sm-6{grid-column-end: span 6;}
	.columns-sm-7{grid-column-end: span 7;}
	.columns-sm-8{grid-column-end: span 8;}
	.columns-sm-9{grid-column-end: span 9;}
	.columns-sm-10{grid-column-end: span 10;}
	.columns-sm-11{grid-column-end: span 11;}
	.columns-sm-12{grid-column-end: span 12;}
	/* end css grid */
	.grid_container1{
		grid-template-columns: repeat(12, 1fr);
	}
	.ic-menu-bar{z-index: 101;margin-left: 20px;}
    nav#menu{position: fixed;z-index: 1001;width: 300px;background: #fff;height: 100%;top: 0px;left: -100%;transition: all 0.5s ease;padding: 10px;padding-top: 30px;margin: 0px;text-align: left;}
    nav#menu.open{left: 0px;}
    nav#menu .close{position: absolute;width: 30px;height: 30px;right: 10px;top: 5px;z-index: 101;}
    nav#menu .close:after{content: '';background-color: #3d4d65;position: absolute;right: 3px;bottom: 14px;width: 24px;height: 2px;-webkit-transform: rotate(45deg);transform: rotate(45deg);}
    nav#menu .close:before{content: '';-webkit-transform: rotate(-45deg);transform: rotate(-45deg);background-color: #3d4d65;position: absolute;right: 3px;top: 14px;width: 24px;height: 2px;}
    nav#menu ul{overflow-y: auto;max-height: calc(100% - 55px);height: 100%;padding: 0px;}
    nav#menu ul::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    nav#menu ul::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    nav#menu ul::-webkit-scrollbar-thumb {
      background: #afafaf;
    }
    /* Handle on hover */
    nav#menu ul::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    nav#menu ul li{display: block;position: relative;padding: 0px;border: none !important;}
    nav#menu ul li a{padding: 7px 10px;color: #353535;display: block;font-size: 14px;border-bottom: solid 1px #ddd;padding-right: 20px;}
	#menu ul li ul{position: relative;width: 100%;background: none;padding-left: 20px; display: none;}
	#menu .dropdown-toggle-btn{display: block;}
	#menu ul li ul ul{display: none;left: 0px !important;}
  .btn{font-size: 15px; padding: 10px 20px;}
  .filter-item .filter-option.active{width: 100%; left: auto;}
}
@media (max-width: 600px){
	/* css grid */
	.columns-xs-1{grid-column-end: span 1;}
	.columns-xs-2{grid-column-end: span 2;}
	.columns-xs-3{grid-column-end: span 3;}
	.columns-xs-4{grid-column-end: span 4;}
	.columns-xs-5{grid-column-end: span 5;}
	.columns-xs-6{grid-column-end: span 6;}
	.columns-xs-7{grid-column-end: span 7;}
	.columns-xs-8{grid-column-end: span 8;}
	.columns-xs-9{grid-column-end: span 9;}
	.columns-xs-10{grid-column-end: span 10;}
	.columns-xs-11{grid-column-end: span 11;}
	.columns-xs-12{grid-column-end: span 12;}
	/* end css grid */
	.tp-product-details-add-to-cart{width: calc(100% - 135px);}
	.tp-product-details-buy-now{width: 100%;}
}
/* Pulse animation */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/* Loading animation */
@-webkit-keyframes tp-loading {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0, 3150;
  }
  100% {
    stroke-dashoffset: -1131;
    stroke-dasharray: 1128, 3138;
  }
}

@keyframes tp-loading {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0, 3150;
  }
  100% {
    stroke-dashoffset: -1131;
    stroke-dasharray: 1128, 3138;
  }
}

/* Rotation animation */
@-webkit-keyframes tp-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes tp-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
