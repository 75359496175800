*{-webkit-box-sizing:border-box;box-sizing:border-box;}
body{padding:0;margin:0;}
#notfound{position:relative;}
.notfound{width:100%;line-height:1.4;}
.notfound .notfound-404 h1{

  font-size: 10.5rem;
  font-weight: 700;
  text-align: center;
  color: #2DA4EF;
}
.notfound p{
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.box-404 {
  background: #2da4ef1a;
  padding-top: 95px;
  text-align: center;
}
.box-404 h3 {
  font-size: 3.8rem;
  font-weight: 700;
  text-align: center;
  color: #2DA4EF;
}
.box-404 h2 {
	font-size: 3.8rem;
	font-weight: 700;
	font-family: var(--font2);
	text-transform: uppercase;
	color: var(--main-color);
	margin-bottom: 83px;

}
.notfound p {
  text-align: center;
  color: #2DA4EF;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 50px;
}
.box-404 .item {
	color: #333;
	transition: 0.3s;
	padding: 38px 15px 50px 15px;
	display: block;
	transition: 0.3s;
  background: #fff;
  margin-bottom: 30px;
}
.box-404 .item:hover {
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.box-404 .item:hover {
	text-decoration: none;
	color: #2DA4EF;
}
.box-404 .item .img-thumb {
	max-width: 76px;
    margin: 0 auto 37px;
}
.box-404 .item .img-thumb img{
  max-width: 100%;
  height: auto;
}

.box-404 .item .ttl {
	font-size: 2rem;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}


.search-404 {
	position: relative;
	border: 1px solid var(--main-color);
	width: 100%;
	max-width: 500px;
  margin: 88px auto 56px;
}
.search-404 input#keywords {
	width: 100%;
	height: 40px;
	border: none;
	padding-left: 10px;
	background: none;
  font-size: 15px;
  background: #fff;
}
.search-404 i {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background: var(--main-color) url('data:image/svg+xml,<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2342 21.0938C17.1271 21.0938 21.0935 17.1273 21.0935 12.2344C21.0935 7.34148 17.1271 3.375 12.2342 3.375C7.34126 3.375 3.37479 7.34148 3.37479 12.2344C3.37479 17.1273 7.34126 21.0938 12.2342 21.0938Z" stroke="white" stroke-width="1.5" stroke-linecap="square"/><path d="M18.4983 18.499L23.6241 23.6249" stroke="white" stroke-width="1.5" stroke-linecap="square"/></svg>') no-repeat center center;
	width: 52px;
}
.btn-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}
.btn-404 a {
  font-size: 2rem;
  font-weight: 400;
  line-height: 27px;
  padding: 10px 40px;
  border: 1px solid var(--main-color);
  margin: 0 10px;
  display: inline-block;
  text-transform: uppercase;
  transition: 0.3s;
  color: #2DA4EF;
}
.btn-404 a.active, .btn-404 a:hover {
  background: var(--main-color);
  color: #fff;
  text-decoration: none;
}
#main_content {
  margin: 106px 0 0;
}
@media only screen and (max-width:767px){
  html {
    font-size: 8px !important;
  }
  .box-404 {
    padding: 50px 15px 0;
  }
  .notfound .notfound-404 h1 {
    font-size: 7.5rem;
}
  .box-404 h2 {
    margin-bottom: 20px;
    font-size: 3rem;
  }
  .box-404 .item .img-thumb {
    max-width: 45px;
    margin: 0 auto 25px;
  }
  .box-404 .item {
    margin-bottom: 10px;
  }
  #main_content {
    margin: 50px 0 0;
  }
  .notfound p {
    margin-bottom: 30px;
    font-size: 2rem;
  }
  .search-404 {
    margin: 40px auto 25px;
  }
  .btn-404 a {
    padding: 10px 15px;
  }
}
